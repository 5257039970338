<template>
  <vs-card>
    <template slot="header">
      <div class="px-2 py-1">
        <h4>Product List</h4>
        <p class="text-sm text-gray-600">
          Manage the available treatment areas your staff can access while scripting the app
        </p>
      </div>
    </template>
    <div id="data-list-list-view" class="px-3 py-1">
        <div class="text-base flex flex-col lg:flex-row flex-no-wrap items-start lg:items-center lg:space-x-2  mb-4">
          <span class="flex items-center">
             Toggle
            <vs-switch class="ml-2" v-model="toggleA" @change="toggleA=true">
              <span slot="on">On</span>
              <span slot="off">Off</span>
            </vs-switch>
          </span>
          <span class="block text-sm">
            to display treatment area(s) for use while scripting in app.
          </span>
        </div>
        
        <div class="text-base flex flex-col lg:flex-row flex-no-wrap items-start lg:items-center lg:space-x-2">
          <span class="flex items-center">
            Toggle 
            <vs-switch class="ml-2" v-model="toggleB" @change="toggleB=false">
              <span slot="on">On</span>
              <span slot="off">Off</span>
            </vs-switch> 
          </span>
          <span class="block text-sm">
            to display treatment area(s) for use while scripting in app.
          </span>
        </div>

        <vs-divider />

        <div class="flex items-center justify-between space-x-4 mb-4 lg:mb-0">
          <vs-select
            placeholder="10"
            autocomplete
            v-model="dataTableParams.limit"
            class="w-4/12 lg:w-auto"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>
          <vs-input
            icon="search"
            placeholder="Search"
            v-model="searchInputString"
            class="flex-1 lg:flex-0"
          />
        </div>

        <ul class="lg:hidden">
            <li :key="indextr" v-for="(tr, indextr) in docs">
              <div class="flex items-center justify-between rounded mb-2 p-2 bg-gray-100">
                <h4 class="text-lg flex-1">
                  {{
                    docs[indextr].areaName
                      ? docs[indextr].areaName
                      : "N/A" | capitalize
                  }}
                  </h4>
                <div>
                  <vs-switch
                    @change="
                      changeProductStatusHandler(
                        docs[indextr].status,
                        docs[indextr].idToUpdate
                      )
                    "
                    class="m-2"
                    v-model="docs[indextr].status"
                  >
                    <span slot="on">On</span>
                    <span slot="off">Off</span>
                  </vs-switch>
                </div>
              </div>
            </li>
        </ul>
        
        <vs-table
          class="hidden lg:block"
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="docs"
          @change-page="handleChangePage"
          @sort="handleSort"
          :max-items="dataTableParams.limit"
          :noDataText="noDataText"
        >

          <template slot="thead">
            <vs-th>Status</vs-th>
            <vs-th sort-key="areaName">Name</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <div>
                  <vs-switch
                    @change="
                      changeFranchiseTreatmentAreaStatus(
                        data[indextr].status,
                        data[indextr].idToUpdate
                      )
                    "
                    val="javascript"
                    v-model="data[indextr].status"
                  >
                    <span slot="on">On</span>
                    <span slot="off">Off</span>
                  </vs-switch>
                </div>
              </vs-td>
              <vs-td :data="data[indextr].areaName">
                {{ data[indextr].areaName }}
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        
        <div
          class="
            m-2
            flex
            justify-center
            items-center
            flex-wrap
          "
          style="height: 35px padding-bottom: 30px"
        >
          <span class="mr-2">
            {{
              dataTableParams.page * dataTableParams.limit -
              (dataTableParams.limit - 1)
            }}
            -
            {{
              totalDocs - dataTableParams.page * dataTableParams.limit > 0
                ? dataTableParams.page * dataTableParams.limit
                : totalDocs
            }}
            of {{ totalDocs }}
          </span>
          <div class="pagination-div" v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="5"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
    </div>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ApplicableAreaList",
  data() {
    return {
      toggleA: true,
      toggleb: false,
      docs: [],
      totalDocs: 0,
      page: 1,
      noDataText: "",
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
        organizationId: null
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      serverResponded: false,
      awaitingSearch: null,
    };
  },
  methods: {
    ...mapActions("organization", [
      "fetchTreatmentAreas",
      "changeTreatmentAreaStatus",
    ]),
    async fetchList() {
      this.$vs.loading();
      this.fetchTreatmentAreas(this.dataTableParams).then((res) => {
        this.$vs.loading.close();
        this.docs = res.data.data[0].docs;
        this.totalDocs = res.data.data[0].pagination[0].total || 0;
        this.page = res.data.data[0].pagination[0].page;
        this.currentPage = res.data.data[0].pagination[0].page;
        this.noDataText = "No Data Available";
        this.serverResponded = true;
      });
    },
    handleSearch(searching) {
      if (this.awaitingSearch) {
        clearTimeout(this.awaitingSearch);
        this.awaitingSearch = null;
      }
      this.awaitingSearch = setTimeout(() => {
        this.serverResponded = false;
        this.dataTableParams.search = searching;
        this.dataTableParams.page = 1;
        this.$refs.table.currentx = 1;
        this.fetchList();
      }, 500);
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchList();
    },
    handleSort(key, active) {
      if (!key) return;

      this.serverResponded = false;
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.fetchList();
    },
    changeFranchiseTreatmentAreaStatus(status, idToUpdate) {
      this.changeTreatmentAreaStatus({ status, idToUpdate, organizationId: this.dataTableParams.organizationId });
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchList();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.noDataText = "Loading...";
    let orgId = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
    this.dataTableParams.organizationId = orgId
    this.fetchList();
  },
};
</script>
